@import '../../../breakpoints/breakpoints.scss';

* {
	margin: 0;
	padding: 0;
	box-sizing: border-box;
}

.App {
	text-align: center;
	min-height: 100vh;
	background: var(--background, #fff);
}

:root {
	--wp--preset--color--black: #000000;
	--wp--preset--color--cyan-bluish-gray: #abb8c3;
	--wp--preset--color--white: #ffffff;
	--wp--preset--color--pale-pink: #f78da7;
	--wp--preset--color--vivid-red: #cf2e2e;
	--wp--preset--color--luminous-vivid-orange: #ff6900;
	--wp--preset--color--luminous-vivid-amber: #fcb900;
	--wp--preset--color--light-green-cyan: #7bdcb5;
	--wp--preset--color--vivid-green-cyan: #00d084;
	--wp--preset--color--pale-cyan-blue: #8ed1fc;
	--wp--preset--color--vivid-cyan-blue: #0693e3;
	--wp--preset--color--vivid-purple: #9b51e0;
	--wp--preset--color--primary: #11181f;
	--wp--preset--color--secondary: #005ae1;
	--wp--preset--color--foreground: #11181f;
	--wp--preset--color--background: #ffffff;
	--wp--preset--color--tertiary: #f7f9fd;
	--wp--preset--gradient--vivid-cyan-blue-to-vivid-purple: linear-gradient(
		135deg,
		rgba(6, 147, 227, 1) 0%,
		rgb(155, 81, 224) 100%
	);
	--wp--preset--gradient--light-green-cyan-to-vivid-green-cyan: linear-gradient(
		135deg,
		rgb(122, 220, 180) 0%,
		rgb(0, 208, 130) 100%
	);
	--wp--preset--gradient--luminous-vivid-amber-to-luminous-vivid-orange: linear-gradient(
		135deg,
		rgba(252, 185, 0, 1) 0%,
		rgba(255, 105, 0, 1) 100%
	);
	--wp--preset--gradient--luminous-vivid-orange-to-vivid-red: linear-gradient(
		135deg,
		rgba(255, 105, 0, 1) 0%,
		rgb(207, 46, 46) 100%
	);
	--wp--preset--gradient--very-light-gray-to-cyan-bluish-gray: linear-gradient(
		135deg,
		rgb(238, 238, 238) 0%,
		rgb(169, 184, 195) 100%
	);
	--wp--preset--gradient--cool-to-warm-spectrum: linear-gradient(
		135deg,
		rgb(74, 234, 220) 0%,
		rgb(151, 120, 209) 20%,
		rgb(207, 42, 186) 40%,
		rgb(238, 44, 130) 60%,
		rgb(251, 105, 98) 80%,
		rgb(254, 248, 76) 100%
	);
	--wp--preset--gradient--blush-light-purple: linear-gradient(
		135deg,
		rgb(255, 206, 236) 0%,
		rgb(152, 150, 240) 100%
	);
	--wp--preset--gradient--blush-bordeaux: linear-gradient(
		135deg,
		rgb(254, 205, 165) 0%,
		rgb(254, 45, 45) 50%,
		rgb(107, 0, 62) 100%
	);
	--wp--preset--gradient--luminous-dusk: linear-gradient(
		135deg,
		rgb(255, 203, 112) 0%,
		rgb(199, 81, 192) 50%,
		rgb(65, 88, 208) 100%
	);
	--wp--preset--gradient--pale-ocean: linear-gradient(
		135deg,
		rgb(255, 245, 203) 0%,
		rgb(182, 227, 212) 50%,
		rgb(51, 167, 181) 100%
	);
	--wp--preset--gradient--electric-grass: linear-gradient(
		135deg,
		rgb(202, 248, 128) 0%,
		rgb(113, 206, 126) 100%
	);
	--wp--preset--gradient--midnight: linear-gradient(
		135deg,
		rgb(2, 3, 129) 0%,
		rgb(40, 116, 252) 100%
	);
	--wp--preset--shadow--natural: 6px 6px 9px
		rgba(0, 0, 0, 0.2);
	--wp--preset--shadow--deep: 12px 12px 50px
		rgba(0, 0, 0, 0.4);
	--wp--preset--shadow--sharp: 6px 6px 0px
		rgba(0, 0, 0, 0.2);
	--wp--preset--shadow--outlined: 6px 6px 0px -3px rgba(255, 255, 255, 1),
		6px 6px rgba(0, 0, 0, 1);
	--wp--preset--shadow--crisp: 6px 6px 0px rgba(0, 0, 0, 1);

	--wp--preset--font-size--small: clamp(
		0.825rem,
		0.825rem + ((1vw - 0.2rem) * 0.433),
		1.0625rem
	);
	--wp--preset--font-size--medium: clamp(
		1rem,
		1rem + ((1vw - 0.2rem) * 0.455),
		1.25rem
	);
	--wp--preset--font-size--large: clamp(
		1.25rem,
		1.25rem + ((1vw - 0.2rem) * 0.569),
		1.563rem
	);
	--wp--preset--font-size--x-large: clamp(
		1.563rem,
		1.563rem + ((1vw - 0.2rem) * 0.709),
		1.953rem
	);
	--wp--preset--font-size--x-small: 3.2rem;
	--wp--preset--font-size--xxx-large: clamp(
		2.441rem,
		2.441rem + ((1vw - 0.2rem) * 1.111),
		3.052rem
	);
	--wp--preset--font-size--xxxx-large: clamp(
		3.052rem,
		3.052rem + ((1vw - 0.2rem) * 1.387),
		3.815rem
	);
	--wp--preset--font-size--xxxxx-large: clamp(
		3.815rem,
		3.815rem + ((1vw - 0.2rem) * 1.733),
		4.768rem
	);
	--wp--preset--font-family--albert-sans: 'Albert Sans',
		sans-serif;
	--wp--preset--font-family--alegreya: Alegreya, serif;
	--wp--preset--font-family--arvo: Arvo, serif;
	--wp--preset--font-family--bodoni-moda: 'Bodoni Moda',
		serif;
	--wp--preset--font-family--cabin: Cabin, sans-serif;
	--wp--preset--font-family--chivo: Chivo, sans-serif;
	--wp--preset--font-family--commissioner: Commissioner,
		sans-serif;
	--wp--preset--font-family--cormorant: Cormorant, serif;
	--wp--preset--font-family--courier-prime: 'Courier Prime',
		monospace;
	--wp--preset--font-family--crimson-pro: 'Crimson Pro',
		serif;
	--wp--preset--font-family--dm-mono: 'DM Mono', monospace;
	--wp--preset--font-family--dm-sans: 'DM Sans', sans-serif;
	--wp--preset--font-family--domine: Domine, serif;
	--wp--preset--font-family--eb-garamond: 'EB Garamond',
		serif;
	--wp--preset--font-family--epilogue: Epilogue, sans-serif;
	--wp--preset--font-family--figtree: Figtree, sans-serif;
	--wp--preset--font-family--fira-sans: 'Fira Sans',
		sans-serif;
	--wp--preset--font-family--fraunces: Fraunces, serif;
	--wp--preset--font-family--ibm-plex-mono: 'IBM Plex Mono',
		monospace;
	--wp--preset--font-family--ibm-plex-sans: 'IBM Plex Sans',
		sans-serif;
	--wp--preset--font-family--josefin-sans: 'Josefin Sans',
		sans-serif;
	--wp--preset--font-family--jost: Jost, sans-serif;
	--wp--preset--font-family--libre-baskerville: 'Libre Baskerville',
		serif;
	--wp--preset--font-family--libre-franklin: 'Libre Franklin',
		sans-serif;
	--wp--preset--font-family--literata: Literata, serif;
	--wp--preset--font-family--lora: Lora, serif;
	--wp--preset--font-family--merriweather: Merriweather,
		serif;
	--wp--preset--font-family--montserrat: Montserrat,
		sans-serif;
	--wp--preset--font-family--newsreader: Newsreader, serif;
	--wp--preset--font-family--nunito: Nunito, sans-serif;
	--wp--preset--font-family--open-sans: 'Open Sans',
		sans-serif;
	--wp--preset--font-family--overpass: Overpass, sans-serif;
	--wp--preset--font-family--petrona: Petrona, serif;
	--wp--preset--font-family--piazzolla: Piazzolla, serif;
	--wp--preset--font-family--playfair-display: 'Playfair Display',
		serif;
	--wp--preset--font-family--plus-jakarta-sans: 'Plus Jakarta Sans',
		sans-serif;
	--wp--preset--font-family--poppins: Poppins, sans-serif;
	--wp--preset--font-family--raleway: Raleway, sans-serif;
	--wp--preset--font-family--roboto: Roboto, sans-serif;
	--wp--preset--font-family--roboto-slab: 'Roboto Slab',
		serif;
	--wp--preset--font-family--rubik: Rubik, sans-serif;
	--wp--preset--font-family--sora: Sora, sans-serif;
	--wp--preset--font-family--source-sans-3: 'Source Sans 3',
		sans-serif;
	--wp--preset--font-family--source-serif-4: 'Source Serif 4',
		serif;
	--wp--preset--font-family--space-mono: 'Space Mono',
		monospace;
	--wp--preset--font-family--texturina: Texturina, serif;
	--wp--preset--font-family--work-sans: 'Work Sans',
		sans-serif;
	--wp--preset--font-family--inter: Inter;
	--wp--preset--spacing--20: 0.44rem;
	--wp--preset--spacing--30: 0.25rem;
	--wp--preset--spacing--40: 1rem;
	--wp--preset--spacing--50: 1.75rem;
	--wp--preset--spacing--60: clamp(
		1.75rem,
		3.5211268vw,
		3.5rem
	);
	--wp--preset--spacing--70: clamp(
		3.5rem,
		7.3943662vw,
		5.25rem
	);
	--wp--preset--spacing--80: clamp(
		5.25rem,
		12.3239437vw,
		8.75rem
	);
}

.wp-block-navigation {
	font-size: var(--wp--preset--font-size--small);
}

.wp-block-navigation a:where(:not(.wp-element-button)) {
	color: inherit;
	text-decoration: none;
}

.wp-block-navigation
	a:where(:not(.wp-element-button)):hover {
	text-decoration: underline;
}

.wp-block-site-logo {
	box-sizing: border-box;
	line-height: 0;
}

.wp-block-site-logo a {
	display: inline-block;
	line-height: 0;
}

.wp-block-site-logo.is-default-size img {
	height: auto;
	width: 120px;
}

.wp-block-site-logo img {
	height: auto;
	max-width: 100%;
}

.wp-block-site-logo a,
.wp-block-site-logo img {
	border-radius: inherit;
}

.wp-block-site-logo.aligncenter {
	margin-left: auto;
	margin-right: auto;
	text-align: center;
}

.wp-block-site-logo.is-style-rounded {
	border-radius: 9999px;
}

.wp-block-navigation .wp-block-navigation-item__label {
	overflow-wrap: break-word;
}

.wp-block-navigation
	.wp-block-navigation-item__description {
	display: none;
}

.wp-block-group {
	box-sizing: border-box;
}

.wp-block-columns {
	align-items: normal !important;
	box-sizing: border-box;
	display: flex;
	flex-wrap: wrap !important;
}

@media (min-width: 782px) {
	.wp-block-columns {
		flex-wrap: nowrap !important;
	}
}

.wp-block-columns.are-vertically-aligned-top {
	align-items: flex-start;
}

.wp-block-columns.are-vertically-aligned-center {
	align-items: center;
}

.wp-block-columns.are-vertically-aligned-bottom {
	align-items: flex-end;
}

@media (max-width: 781px) {
	.wp-block-columns:not(.is-not-stacked-on-mobile)
		> .wp-block-column {
		flex-basis: 100% !important;
	}
}

@media (min-width: 782px) {
	.wp-block-columns:not(.is-not-stacked-on-mobile)
		> .wp-block-column {
		flex-basis: 0;
		flex-grow: 1;
	}

	.wp-block-columns:not(.is-not-stacked-on-mobile)
		> .wp-block-column[style*='flex-basis'] {
		flex-grow: 0;
	}
}

.wp-block-columns.is-not-stacked-on-mobile {
	flex-wrap: nowrap !important;
}

.wp-block-columns.is-not-stacked-on-mobile
	> .wp-block-column {
	flex-basis: 0;
	flex-grow: 1;
}

.wp-block-columns.is-not-stacked-on-mobile
	> .wp-block-column[style*='flex-basis'] {
	flex-grow: 0;
}

:where(.wp-block-columns) {
	margin-bottom: 1.75em;
}

:where(.wp-block-columns.has-background) {
	padding: 1.25em 2.375em;
}

.wp-block-column {
	flex-grow: 1;
	min-width: 0;
	overflow-wrap: break-word;
	word-break: break-word;
}

.wp-block-column.is-vertically-aligned-top {
	align-self: flex-start;
}

.wp-block-column.is-vertically-aligned-center {
	align-self: center;
}

.wp-block-column.is-vertically-aligned-bottom {
	align-self: flex-end;
}

.wp-block-column.is-vertically-aligned-stretch {
	align-self: stretch;
}

.wp-block-column.is-vertically-aligned-bottom,
.wp-block-column.is-vertically-aligned-center,
.wp-block-column.is-vertically-aligned-top {
	width: 100%;
}

.wp-block-image img {
	box-sizing: border-box;
	height: auto;
	max-width: 100%;
	vertical-align: bottom;
}

.wp-block-image[style*='border-radius'] img,
.wp-block-image[style*='border-radius'] > a {
	border-radius: inherit;
}

.wp-block-image.has-custom-border img {
	box-sizing: border-box;
}

.wp-block-image.aligncenter {
	text-align: center;
}

.wp-block-image.alignfull img,
.wp-block-image.alignwide img {
	height: auto;
	width: 100%;
}

.wp-block-image .aligncenter,
.wp-block-image .alignleft,
.wp-block-image .alignright,
.wp-block-image.aligncenter,
.wp-block-image.alignleft,
.wp-block-image.alignright {
	display: table;
}

.wp-block-image .aligncenter > figcaption,
.wp-block-image .alignleft > figcaption,
.wp-block-image .alignright > figcaption,
.wp-block-image.aligncenter > figcaption,
.wp-block-image.alignleft > figcaption,
.wp-block-image.alignright > figcaption {
	caption-side: bottom;
	display: table-caption;
}

.wp-block-image .alignleft {
	float: left;
	margin: 0.5em 1em 0.5em 0;
}

.wp-block-image .alignright {
	float: right;
	margin: 0.5em 0 0.5em 1em;
}

.wp-block-image .aligncenter {
	margin-left: auto;
	margin-right: auto;
}

.wp-block-image figcaption {
	margin-bottom: 1em;
	margin-top: 0.5em;
}

.wp-block-image .is-style-rounded img,
.wp-block-image.is-style-circle-mask img,
.wp-block-image.is-style-rounded img {
	border-radius: 9999px;
}

@supports ((-webkit-mask-image: none) or (mask-image: none))
	or (-webkit-mask-image: none) {
	.wp-block-image.is-style-circle-mask img {
		border-radius: 0;
		-webkit-mask-image: url('data:image/svg+xml;utf8,<svg viewBox="0 0 100 100" xmlns="http://www.w3.org/2000/svg"><circle cx="50" cy="50" r="50"/></svg>');
		mask-image: url('data:image/svg+xml;utf8,<svg viewBox="0 0 100 100" xmlns="http://www.w3.org/2000/svg"><circle cx="50" cy="50" r="50"/></svg>');
		mask-mode: alpha;
		-webkit-mask-position: center;
		mask-position: center;
		-webkit-mask-repeat: no-repeat;
		mask-repeat: no-repeat;
		-webkit-mask-size: contain;
		mask-size: contain;
	}
}

.wp-block-image :where(.has-border-color) {
	border-style: solid;
}

.wp-block-image :where([style*='border-top-color']) {
	border-top-style: solid;
}

.wp-block-image :where([style*='border-right-color']) {
	border-right-style: solid;
}

.wp-block-image :where([style*='border-bottom-color']) {
	border-bottom-style: solid;
}

.wp-block-image :where([style*='border-left-color']) {
	border-left-style: solid;
}

.wp-block-image :where([style*='border-width']) {
	border-style: solid;
}

.wp-block-image :where([style*='border-top-width']) {
	border-top-style: solid;
}

.wp-block-image :where([style*='border-right-width']) {
	border-right-style: solid;
}

.wp-block-image :where([style*='border-bottom-width']) {
	border-bottom-style: solid;
}

.wp-block-image :where([style*='border-left-width']) {
	border-left-style: solid;
}

.wp-block-image figure {
	margin: 0;
}

.wp-lightbox-container {
	display: flex;
	flex-direction: column;
	position: relative;
}

.wp-lightbox-container img {
	cursor: zoom-in;
}

.wp-lightbox-container img:hover + button {
	opacity: 1;
}

.wp-lightbox-container button {
	align-items: center;
	-webkit-backdrop-filter: blur(16px) saturate(180%);
	backdrop-filter: blur(16px) saturate(180%);
	background-color: rgba(90, 90, 90, 0.25);
	border: none;
	border-radius: 4px;
	cursor: zoom-in;
	display: flex;
	height: 20px;
	justify-content: center;
	opacity: 0;
	padding: 0;
	position: absolute;
	right: 16px;
	text-align: center;
	top: 16px;
	transition: opacity 0.2s ease;
	width: 20px;
	z-index: 100;
}

.wp-lightbox-container button:focus-visible {
	outline: 3px auto rgba(90, 90, 90, 0.25);
	outline: 3px auto -webkit-focus-ring-color;
	outline-offset: 3px;
}

.wp-lightbox-container button:hover {
	cursor: pointer;
	opacity: 1;
}

.wp-lightbox-container button:focus {
	opacity: 1;
}

.wp-lightbox-container button:focus,
.wp-lightbox-container button:hover,
.wp-lightbox-container
	button:not(:hover):not(:active):not(.has-background) {
	background-color: rgba(90, 90, 90, 0.25);
	border: none;
}

.wp-lightbox-overlay {
	box-sizing: border-box;
	cursor: zoom-out;
	height: 100vh;
	left: 0;
	overflow: hidden;
	position: fixed;
	top: 0;
	visibility: hidden;
	width: 100%;
	z-index: 100000;
}

.wp-lightbox-overlay .close-button {
	align-items: center;
	cursor: pointer;
	display: flex;
	justify-content: center;
	min-height: 40px;
	min-width: 40px;
	padding: 0;
	position: absolute;
	right: calc(env(safe-area-inset-right) + 16px);
	top: calc(env(safe-area-inset-top) + 16px);
	z-index: 5000000;
}

.wp-lightbox-overlay .close-button:focus,
.wp-lightbox-overlay .close-button:hover,
.wp-lightbox-overlay
	.close-button:not(:hover):not(:active):not(
		.has-background
	) {
	background: none;
	border: none;
}

.wp-lightbox-overlay .lightbox-image-container {
	height: var(--wp--lightbox-container-height);
	left: 50%;
	overflow: hidden;
	position: absolute;
	top: 50%;
	transform: translate(-50%, -50%);
	transform-origin: top left;
	width: var(--wp--lightbox-container-width);
	z-index: 9999999999;
}

.wp-lightbox-overlay .wp-block-image {
	align-items: center;
	box-sizing: border-box;
	display: flex;
	height: 100%;
	justify-content: center;
	margin: 0;
	position: relative;
	transform-origin: 0 0;
	width: 100%;
	z-index: 3000000;
}

.wp-lightbox-overlay .wp-block-image img {
	height: var(--wp--lightbox-image-height);
	min-height: var(--wp--lightbox-image-height);
	min-width: var(--wp--lightbox-image-width);
	width: var(--wp--lightbox-image-width);
}

.wp-lightbox-overlay .wp-block-image figcaption {
	display: none;
}

.wp-lightbox-overlay button {
	background: none;
	border: none;
}

.wp-lightbox-overlay .scrim {
	background-color: #fff;
	height: 100%;
	opacity: 0.9;
	position: absolute;
	width: 100%;
	z-index: 2000000;
}

.wp-lightbox-overlay.active {
	animation: turn-on-visibility 0.25s both;
	visibility: visible;
}

.wp-lightbox-overlay.active img {
	animation: turn-on-visibility 0.35s both;
}

.wp-lightbox-overlay.hideanimationenabled:not(.active) {
	animation: turn-off-visibility 0.35s both;
}

.wp-lightbox-overlay.hideanimationenabled:not(.active) img {
	animation: turn-off-visibility 0.25s both;
}

@media (prefers-reduced-motion: no-preference) {
	.wp-lightbox-overlay.zoom.active {
		animation: none;
		opacity: 1;
		visibility: visible;
	}

	.wp-lightbox-overlay.zoom.active
		.lightbox-image-container {
		animation: lightbox-zoom-in 0.4s;
	}

	.wp-lightbox-overlay.zoom.active
		.lightbox-image-container
		img {
		animation: none;
	}

	.wp-lightbox-overlay.zoom.active .scrim {
		animation: turn-on-visibility 0.4s forwards;
	}

	.wp-lightbox-overlay.zoom.hideanimationenabled:not(
			.active
		) {
		animation: none;
	}

	.wp-lightbox-overlay.zoom.hideanimationenabled:not(
			.active
		)
		.lightbox-image-container {
		animation: lightbox-zoom-out 0.4s;
	}

	.wp-lightbox-overlay.zoom.hideanimationenabled:not(
			.active
		)
		.lightbox-image-container
		img {
		animation: none;
	}

	.wp-lightbox-overlay.zoom.hideanimationenabled:not(
			.active
		)
		.scrim {
		animation: turn-off-visibility 0.4s forwards;
	}
}

@keyframes turn-on-visibility {
	0% {
		opacity: 0;
	}

	to {
		opacity: 1;
	}
}

@keyframes turn-off-visibility {
	0% {
		opacity: 1;
		visibility: visible;
	}

	99% {
		opacity: 0;
		visibility: visible;
	}

	to {
		opacity: 0;
		visibility: hidden;
	}
}

@keyframes lightbox-zoom-in {
	0% {
		transform: translate(
				calc(
					(-100vw + var(--wp--lightbox-scrollbar-width)) / 2 +
						var(--wp--lightbox-initial-left-position)
				),
				calc(
					-50vh + var(--wp--lightbox-initial-top-position)
				)
			)
			scale(var(--wp--lightbox-scale));
	}

	to {
		transform: translate(-50%, -50%) scale(1);
	}
}

@keyframes lightbox-zoom-out {
	0% {
		transform: translate(-50%, -50%) scale(1);
		visibility: visible;
	}

	99% {
		visibility: visible;
	}

	to {
		transform: translate(
				calc(
					(-100vw + var(--wp--lightbox-scrollbar-width)) / 2 +
						var(--wp--lightbox-initial-left-position)
				),
				calc(
					-50vh + var(--wp--lightbox-initial-top-position)
				)
			)
			scale(var(--wp--lightbox-scale));
		visibility: hidden;
	}
}

h1.has-background,
h2.has-background,
h3.has-background,
h4.has-background,
h5.has-background,
h6.has-background {
	padding: 1.25em 2.375em;
}

h1.has-text-align-left[style*='writing-mode']:where(
		[style*='vertical-lr']
	),
h1.has-text-align-right[style*='writing-mode']:where(
		[style*='vertical-rl']
	),
h2.has-text-align-left[style*='writing-mode']:where(
		[style*='vertical-lr']
	),
h2.has-text-align-right[style*='writing-mode']:where(
		[style*='vertical-rl']
	),
h3.has-text-align-left[style*='writing-mode']:where(
		[style*='vertical-lr']
	),
h3.has-text-align-right[style*='writing-mode']:where(
		[style*='vertical-rl']
	),
h4.has-text-align-left[style*='writing-mode']:where(
		[style*='vertical-lr']
	),
h4.has-text-align-right[style*='writing-mode']:where(
		[style*='vertical-rl']
	),
h5.has-text-align-left[style*='writing-mode']:where(
		[style*='vertical-lr']
	),
h5.has-text-align-right[style*='writing-mode']:where(
		[style*='vertical-rl']
	),
h6.has-text-align-left[style*='writing-mode']:where(
		[style*='vertical-lr']
	),
h6.has-text-align-right[style*='writing-mode']:where(
		[style*='vertical-rl']
	) {
	rotate: 180deg;
}

.wp-block-heading {
	color: var(--wp--preset--color--foreground);
}

.wp-block-heading a:where(:not(.wp-element-button)) {
	color: var(--wp--preset--color--foreground);
}

.is-small-text {
	font-size: 0.875em;
}

.is-regular-text {
	font-size: 1em;
}

.is-large-text {
	font-size: 2.25em;
}

.is-larger-text {
	font-size: 3em;
}

.has-drop-cap:not(:focus):first-letter {
	float: left;
	font-size: 8.4em;
	font-style: normal;
	font-weight: 100;
	line-height: 0.68;
	margin: 0.05em 0.1em 0 0;
	text-transform: uppercase;
}

body.rtl .has-drop-cap:not(:focus):first-letter {
	float: none;
	margin-left: 0.1em;
}

p.has-drop-cap.has-background {
	overflow: hidden;
}

p.has-background {
	padding: 1.25em 2.375em;
}

:where(p.has-text-color:not(.has-link-color)) a {
	color: inherit;
}

p.has-text-align-left[style*='writing-mode:vertical-lr'],
p.has-text-align-right[style*='writing-mode:vertical-rl'] {
	rotate: 180deg;
}

.wp-block-spacer {
	clear: both;
}

ol,
ul {
	box-sizing: border-box;
}

ol.has-background,
ul.has-background {
	padding: 1.25em 2.375em;
}

ol,
ul {
	padding-left: var(--wp--preset--spacing--50);
}

.wp-block-post-featured-image {
	margin-left: 0;
	margin-right: 0;
}

.wp-block-post-featured-image a {
	display: block;
	height: 100%;
}

.wp-block-post-featured-image img {
	box-sizing: border-box;
	height: auto;
	max-width: 100%;
	vertical-align: bottom;
	width: 100%;
}

.wp-block-post-featured-image.alignfull img,
.wp-block-post-featured-image.alignwide img {
	width: 100%;
}

.wp-block-post-featured-image
	.wp-block-post-featured-image__overlay.has-background-dim {
	background-color: #000;
	inset: 0;
	position: absolute;
}

.wp-block-post-featured-image {
	position: relative;
}

.wp-block-post-featured-image
	.wp-block-post-featured-image__overlay.has-background-gradient {
	background-color: transparent;
}

.wp-block-post-featured-image
	.wp-block-post-featured-image__overlay.has-background-dim-0 {
	opacity: 0;
}

.wp-block-post-featured-image
	.wp-block-post-featured-image__overlay.has-background-dim-10 {
	opacity: 0.1;
}

.wp-block-post-featured-image
	.wp-block-post-featured-image__overlay.has-background-dim-20 {
	opacity: 0.2;
}

.wp-block-post-featured-image
	.wp-block-post-featured-image__overlay.has-background-dim-30 {
	opacity: 0.3;
}

.wp-block-post-featured-image
	.wp-block-post-featured-image__overlay.has-background-dim-40 {
	opacity: 0.4;
}

.wp-block-post-featured-image
	.wp-block-post-featured-image__overlay.has-background-dim-50 {
	opacity: 0.5;
}

.wp-block-post-featured-image
	.wp-block-post-featured-image__overlay.has-background-dim-60 {
	opacity: 0.6;
}

.wp-block-post-featured-image
	.wp-block-post-featured-image__overlay.has-background-dim-70 {
	opacity: 0.7;
}

.wp-block-post-featured-image
	.wp-block-post-featured-image__overlay.has-background-dim-80 {
	opacity: 0.8;
}

.wp-block-post-featured-image
	.wp-block-post-featured-image__overlay.has-background-dim-90 {
	opacity: 0.9;
}

.wp-block-post-featured-image
	.wp-block-post-featured-image__overlay.has-background-dim-100 {
	opacity: 1;
}

.wp-block-post-featured-image:where(
		.alignleft,
		.alignright
	) {
	width: 100%;
}

.wp-block-post-title {
	box-sizing: border-box;
	word-break: break-word;
}

.wp-block-post-title a {
	display: inline-block;
}

.wp-block-post-title {
	color: var(--wp--preset--color--foreground);
	font-size: var(--wp--preset--font-size--xxx-large);
}

.wp-block-post-title a:where(:not(.wp-element-button)) {
	color: var(--wp--preset--color--foreground);
	text-decoration: none;
}

.wp-block-post-title
	a:where(:not(.wp-element-button)):hover {
	color: var(--wp--preset--color--primary);
	text-decoration: underline;
}

:where(.wp-block-post-excerpt) {
	margin-bottom: var(--wp--style--block-gap);
	margin-top: var(--wp--style--block-gap);
}

.wp-block-post-excerpt__excerpt {
	margin-bottom: 0;
	margin-top: 0;
}

.wp-block-post-excerpt__more-text {
	margin-bottom: 0;
	margin-top: var(--wp--style--block-gap);
}

.wp-block-post-excerpt__more-link {
	display: inline-block;
	text-decoration: none;
	color: var(--wp--preset--color--vivid-red) !important;

	&:hover {
		text-decoration: none;
	}
}

.wp-block-post-template {
	list-style: none;
	margin-bottom: 0;
	margin-top: 0;
	max-width: 100%;
	padding: 0;
}

.wp-block-post-template.wp-block-post-template {
	background: none;
}

.wp-block-post-template.is-flex-container {
	display: flex;
	flex-direction: row;
	flex-wrap: wrap;
	gap: 1.25em;
}

.wp-block-post-template.is-flex-container > li {
	margin: 0;
	width: 100%;
}

@media (min-width: 600px) {
	.wp-block-post-template.is-flex-container.is-flex-container.columns-2
		> li {
		width: calc(50% - 0.625em);
	}

	.wp-block-post-template.is-flex-container.is-flex-container.columns-3
		> li {
		width: calc(33.33333% - 0.83333em);
	}

	.wp-block-post-template.is-flex-container.is-flex-container.columns-4
		> li {
		width: calc(25% - 0.9375em);
	}

	.wp-block-post-template.is-flex-container.is-flex-container.columns-5
		> li {
		width: calc(20% - 1em);
	}

	.wp-block-post-template.is-flex-container.is-flex-container.columns-6
		> li {
		width: calc(16.66667% - 1.04167em);
	}
}

@media (max-width: 600px) {
	.wp-block-post-template-is-layout-grid.wp-block-post-template-is-layout-grid.wp-block-post-template-is-layout-grid.wp-block-post-template-is-layout-grid {
		grid-template-columns: 1fr;
	}
}

.wp-block-post-template-is-layout-constrained
	> li
	> .alignright,
.wp-block-post-template-is-layout-flow > li > .alignright {
	float: right;
	margin-inline-end: 0;
	margin-inline-start: 2em;
}

.wp-block-post-template-is-layout-constrained
	> li
	> .alignleft,
.wp-block-post-template-is-layout-flow > li > .alignleft {
	float: left;
	margin-inline-end: 2em;
	margin-inline-start: 0;
}

.wp-block-post-template-is-layout-constrained
	> li
	> .aligncenter,
.wp-block-post-template-is-layout-flow > li > .aligncenter {
	margin-inline-end: auto;
	margin-inline-start: auto;
}

.wp-block-site-title a {
	color: inherit;
}

.wp-block-site-title {
	font-size: var(--wp--preset--font-size--medium);
	font-style: normal;
}

.wp-block-site-title a:where(:not(.wp-element-button)) {
	color: var(--wp--preset--color--foreground);
	text-decoration: none;
}

.wp-block-site-title
	a:where(:not(.wp-element-button)):hover {
	color: var(--wp--preset--color--foreground);
	text-decoration: underline;
}

img.wp-smiley,
img.emoji {
	display: inline !important;
	border: none !important;
	box-shadow: none !important;
	height: 1em !important;
	width: 1em !important;
	margin: 0 0.07em !important;
	vertical-align: -0.1em !important;
	background: none !important;
	padding: 0 !important;
}

:root {
	--wp-admin-theme-color: #007cba;
	--wp-admin-theme-color--rgb: 0, 124, 186;
	--wp-admin-theme-color-darker-10: #006ba1;
	--wp-admin-theme-color-darker-10--rgb: 0, 107, 161;
	--wp-admin-theme-color-darker-20: #005a87;
	--wp-admin-theme-color-darker-20--rgb: 0, 90, 135;
	--wp-admin-border-width-focus: 2px;
	--wp-block-synced-color: #7a00df;
	--wp-block-synced-color--rgb: 122, 0, 223;
}

@media (-webkit-min-device-pixel-ratio: 2),
	(min-resolution: 192dpi) {
	:root {
		--wp-admin-border-width-focus: 1.5px;
	}
}

.wp-element-button {
	cursor: pointer;
}

:root {
	--wp--preset--font-size--normal: 16px;
	--wp--preset--font-size--huge: 42px;
}

:root .has-very-light-gray-background-color {
	background-color: #eee;
}

:root .has-very-dark-gray-background-color {
	background-color: #313131;
}

:root .has-very-light-gray-color {
	color: #eee;
}

:root .has-very-dark-gray-color {
	color: #313131;
}

:root
	.has-vivid-green-cyan-to-vivid-cyan-blue-gradient-background {
	background: linear-gradient(135deg, #00d084, #0693e3);
}

:root .has-purple-crush-gradient-background {
	background: linear-gradient(
		135deg,
		#34e2e4,
		#4721fb 50%,
		#ab1dfe
	);
}

:root .has-hazy-dawn-gradient-background {
	background: linear-gradient(135deg, #faaca8, #dad0ec);
}

:root .has-subdued-olive-gradient-background {
	background: linear-gradient(135deg, #fafae1, #67a671);
}

:root .has-atomic-cream-gradient-background {
	background: linear-gradient(135deg, #fdd79a, #004a59);
}

:root .has-nightshade-gradient-background {
	background: linear-gradient(135deg, #330968, #31cdcf);
}

:root .has-midnight-gradient-background {
	background: linear-gradient(135deg, #020381, #2874fc);
}

.has-regular-font-size {
	font-size: 1em;
}

.has-larger-font-size {
	font-size: 2.625em;
}

.has-normal-font-size {
	font-size: var(--wp--preset--font-size--normal);
}

.has-huge-font-size {
	font-size: var(--wp--preset--font-size--huge);
}

.has-text-align-center {
	text-align: center;
}

.has-text-align-left {
	text-align: left;
}

.has-text-align-right {
	text-align: right;
}

#end-resizable-editor-section {
	display: none;
}

.aligncenter {
	clear: both;
}

.items-justified-left {
	justify-content: flex-start;
}

.items-justified-center {
	justify-content: center;
}

.items-justified-right {
	justify-content: flex-end;
}

.items-justified-space-between {
	justify-content: space-between;
}

.screen-reader-text {
	clip: rect(1px, 1px, 1px, 1px);
	word-wrap: normal !important;
	border: 0;
	clip-path: inset(50%);
	height: 1px;
	margin: -1px;
	overflow: hidden;
	padding: 0;
	position: absolute;
	width: 1px;
}

.screen-reader-text:focus {
	clip: auto !important;
	background-color: #ddd;
	clip-path: none;
	color: #444;
	display: block;
	font-size: 1em;
	height: auto;
	left: 5px;
	line-height: normal;
	padding: 15px 23px 14px;
	text-decoration: none;
	top: 5px;
	width: auto;
	z-index: 100000;
}

html :where(.has-border-color) {
	border-style: solid;
}

html :where([style*='border-top-color']) {
	border-top-style: solid;
}

html :where([style*='border-right-color']) {
	border-right-style: solid;
}

html :where([style*='border-bottom-color']) {
	border-bottom-style: solid;
}

html :where([style*='border-left-color']) {
	border-left-style: solid;
}

html :where([style*='border-width']) {
	border-style: solid;
}

html :where([style*='border-top-width']) {
	border-top-style: solid;
}

html :where([style*='border-right-width']) {
	border-right-style: solid;
}

html :where([style*='border-bottom-width']) {
	border-bottom-style: solid;
}

html :where([style*='border-left-width']) {
	border-left-style: solid;
}

html :where(img[class*='wp-image-']) {
	height: auto;
	max-width: 100%;
}

:where(figure) {
	margin: 0 0 1em;
}

html :where(.is-position-sticky) {
	--wp-admin--admin-bar--position-offset: var(
		--wp-admin--admin-bar--height,
		0px
	);
}

@media screen and (max-width: 600px) {
	html :where(.is-position-sticky) {
		--wp-admin--admin-bar--position-offset: 0px;
	}
}

.has-text-align-justify {
	text-align: justify;
}

.skip-link.screen-reader-text {
	border: 0;
	clip: rect(1px, 1px, 1px, 1px);
	clip-path: inset(50%);
	height: 1px;
	margin: -1px;
	overflow: hidden;
	padding: 0;
	position: absolute !important;
	width: 1px;
	word-wrap: normal !important;
}

.skip-link.screen-reader-text:focus {
	background-color: #eee;
	clip: auto !important;
	clip-path: none;
	color: #444;
	display: block;
	font-size: 1em;
	height: auto;
	left: 5px;
	line-height: normal;
	padding: 15px 23px 14px;
	text-decoration: none;
	top: 5px;
	width: auto;
	z-index: 100000;
}

body {
	margin: 0;
	--wp--style--global--content-size: 620px;
	--wp--style--global--wide-size: 1200px;
}

.wp-site-blocks {
	padding-top: var(--wp--style--root--padding-top);
	padding-bottom: var(--wp--style--root--padding-bottom);
}

.has-global-padding {
	padding-right: var(--wp--style--root--padding-right);
	padding-left: var(--wp--style--root--padding-left);
}

.has-global-padding
	:where(.has-global-padding:not(.wp-block-block)) {
	padding-right: 0;
	padding-left: 0;
}

.has-global-padding > .alignfull {
	margin-right: calc(
		var(--wp--style--root--padding-right) * -1
	);
	margin-left: calc(
		var(--wp--style--root--padding-left) * -1
	);
}

.has-global-padding
	:where(.has-global-padding:not(.wp-block-block))
	> .alignfull {
	margin-right: 0;
	margin-left: 0;
}

.has-global-padding
	> .alignfull:where(
		:not(.has-global-padding):not(.is-layout-flex):not(
				.is-layout-grid
			)
	)
	> :where(
		[class*='wp-block-']:not(.alignfull):not([class*='__']),
		.wp-block:not(.alignfull),
		p,
		h1,
		h2,
		h3,
		h4,
		h5,
		h6,
		ul,
		ol
	) {
	padding-right: var(--wp--style--root--padding-right);
	padding-left: var(--wp--style--root--padding-left);
}

.has-global-padding
	:where(.has-global-padding)
	> .alignfull:where(:not(.has-global-padding))
	> :where(
		[class*='wp-block-']:not(.alignfull):not([class*='__']),
		.wp-block:not(.alignfull),
		p,
		h1,
		h2,
		h3,
		h4,
		h5,
		h6,
		ul,
		ol
	) {
	padding-right: 0;
	padding-left: 0;
}

.wp-site-blocks > .alignleft {
	float: left;
	margin-right: 2em;
}

.wp-site-blocks > .alignright {
	float: right;
	margin-left: 2em;
}

.wp-site-blocks > .aligncenter {
	justify-content: center;
	margin-left: auto;
	margin-right: auto;
}

:where(.wp-site-blocks) > * {
	margin-block-start: var(--wp--preset--spacing--50);
	margin-block-end: 0;
}

:where(.wp-site-blocks) > :first-child:first-child {
	margin-block-start: 0;
}

:where(.wp-site-blocks) > :last-child:last-child {
	margin-block-end: 0;
}

body {
	--wp--style--block-gap: var(--wp--preset--spacing--50);
}

:where(body .is-layout-flow) > :first-child:first-child {
	margin-block-start: 0;
}

:where(body .is-layout-flow) > :last-child:last-child {
	margin-block-end: 0;
}

:where(body .is-layout-flow) > * {
	margin-block-start: var(--wp--preset--spacing--50);
	margin-block-end: 0;
}

:where(body .is-layout-constrained)
	> :first-child:first-child {
	margin-block-start: 0;
}

:where(body .is-layout-constrained)
	> :last-child:last-child {
	margin-block-end: 0;
}

:where(body .is-layout-constrained) > * {
	margin-block-start: var(--wp--preset--spacing--50);
	margin-block-end: 0;
}

:where(body .is-layout-flex) {
	gap: var(--wp--preset--spacing--50);
}

:where(body .is-layout-grid) {
	gap: var(--wp--preset--spacing--50);
}

body .is-layout-flow > .alignleft {
	float: left;
	margin-inline-start: 0;
	margin-inline-end: 2em;
}

body .is-layout-flow > .alignright {
	float: right;
	margin-inline-start: 2em;
	margin-inline-end: 0;
}

body .is-layout-flow > .aligncenter {
	margin-left: auto !important;
	margin-right: auto !important;
}

body .is-layout-constrained > .alignleft {
	float: left;
	margin-inline-start: 0;
	margin-inline-end: 2em;
}

body .is-layout-constrained > .alignright {
	float: right;
	margin-inline-start: 2em;
	margin-inline-end: 0;
}

body .is-layout-constrained > .aligncenter {
	margin-left: auto !important;
	margin-right: auto !important;
}

body
	.is-layout-constrained
	> :where(
		:not(.alignleft):not(.alignright):not(.alignfull)
	) {
	max-width: var(--wp--style--global--content-size);
}

body .is-layout-constrained > .alignwide {
	max-width: var(--wp--style--global--wide-size);
	margin: 0 auto;
}

body .is-layout-flex {
	display: flex;
}

body .is-layout-flex {
	flex-wrap: wrap;
	align-items: center;
}

body .is-layout-flex > * {
	margin: 0;
}

body .is-layout-grid {
	display: grid;
}

body .is-layout-grid > * {
	margin: 0;
}

body {
	background-color: var(--wp--preset--color--background);
	color: var(--wp--preset--color--foreground);
	font-family: var(--wp--preset--font-family--inter);
	font-size: var(--wp--preset--font-size--small);
	font-style: normal;
	font-weight: 400;
	line-height: 1.6;
	--wp--style--root--padding-top: 0px;
	--wp--style--root--padding-right: var(
		--wp--preset--spacing--50
	);
	--wp--style--root--padding-bottom: 0px;
	--wp--style--root--padding-left: var(
		--wp--preset--spacing--50
	);
}

a:where(:not(.wp-element-button)) {
	color: var(--wp--preset--color--foreground);
	text-decoration: underline;
}

a:where(:not(.wp-element-button)):hover {
	color: var(--wp--preset--color--primary);
	text-decoration: none;
}

h1,
h2,
h3,
h4,
h5,
h6 {
	font-family: var(--wp--preset--font-family--inter);
	font-style: normal;
	font-weight: 600;
	line-height: 1.2;
}

h1 {
	font-size: var(--wp--preset--font-size--xxx-large);
	line-height: 1.127;
}

h2 {
	font-size: var(--wp--preset--font-size--xx-large);
	line-height: 1.152;
}

h3 {
	font-size: var(--wp--preset--font-size--x-large);
	line-height: 1.28;
}

h4 {
	font-size: var(--wp--preset--font-size--large);
	line-height: 1.5;
}

h5 {
	font-size: var(--wp--preset--font-size--medium);
	line-height: 1.5;
}

h6 {
	font-size: var(--wp--preset--font-size--medium);
	line-height: 1.5;
}

.wp-element-button,
.wp-block-button__link {
	background-color: var(--wp--preset--color--primary);
	border-radius: 0.25rem;
	border-width: 0;
	color: var(--wp--preset--color--background);
	font-family: inherit;
	font-size: inherit;
	line-height: inherit;
	padding: calc(0.667em + 2px) calc(1.333em + 2px);
	text-decoration: none;
}

.wp-element-button:hover,
.wp-block-button__link:hover {
	background-color: var(--wp--preset--color--secondary);
	color: var(--wp--preset--color--background);
}

.wp-element-button:focus,
.wp-block-button__link:focus {
	background-color: var(--wp--preset--color--foreground);
	color: var(--wp--preset--color--background);
	outline-color: var(--wp--preset--color--primary);
	outline-offset: 2px;
	outline-style: dotted;
	outline-width: 1px;
}

.wp-element-button:active,
.wp-block-button__link:active {
	background-color: var(--wp--preset--color--foreground);
	color: var(--wp--preset--color--background);
}

.has-black-color {
	color: var(--wp--preset--color--black) !important;
}

.has-cyan-bluish-gray-color {
	color: var(
		--wp--preset--color--cyan-bluish-gray
	) !important;
}

.has-white-color {
	color: var(--wp--preset--color--white) !important;
}

.has-pale-pink-color {
	color: var(--wp--preset--color--pale-pink) !important;
}

.has-vivid-red-color {
	color: var(--wp--preset--color--vivid-red) !important;
}

.has-luminous-vivid-orange-color {
	color: var(
		--wp--preset--color--luminous-vivid-orange
	) !important;
}

.has-luminous-vivid-amber-color {
	color: var(
		--wp--preset--color--luminous-vivid-amber
	) !important;
}

.has-light-green-cyan-color {
	color: var(
		--wp--preset--color--light-green-cyan
	) !important;
}

.has-vivid-green-cyan-color {
	color: var(
		--wp--preset--color--vivid-green-cyan
	) !important;
}

.has-pale-cyan-blue-color {
	color: var(
		--wp--preset--color--pale-cyan-blue
	) !important;
}

.has-vivid-cyan-blue-color {
	color: var(
		--wp--preset--color--vivid-cyan-blue
	) !important;
}

.has-vivid-purple-color {
	color: var(--wp--preset--color--vivid-purple) !important;
}

.has-primary-color {
	color: var(--wp--preset--color--primary) !important;
}

.has-secondary-color {
	color: var(--wp--preset--color--secondary) !important;
}

.has-foreground-color {
	color: var(--wp--preset--color--foreground) !important;
}

.has-background-color {
	color: var(--wp--preset--color--background) !important;
}

.has-tertiary-color {
	color: var(--wp--preset--color--tertiary) !important;
}

.has-black-background-color {
	background-color: var(
		--wp--preset--color--black
	) !important;
}

.has-cyan-bluish-gray-background-color {
	background-color: var(
		--wp--preset--color--cyan-bluish-gray
	) !important;
}

.has-white-background-color {
	background-color: var(
		--wp--preset--color--white
	) !important;
}

.has-pale-pink-background-color {
	background-color: var(
		--wp--preset--color--pale-pink
	) !important;
}

.has-vivid-red-background-color {
	background-color: var(
		--wp--preset--color--vivid-red
	) !important;
}

.has-luminous-vivid-orange-background-color {
	background-color: var(
		--wp--preset--color--luminous-vivid-orange
	) !important;
}

.has-luminous-vivid-amber-background-color {
	background-color: var(
		--wp--preset--color--luminous-vivid-amber
	) !important;
}

.has-light-green-cyan-background-color {
	background-color: var(
		--wp--preset--color--light-green-cyan
	) !important;
}

.has-vivid-green-cyan-background-color {
	background-color: var(
		--wp--preset--color--vivid-green-cyan
	) !important;
}

.has-pale-cyan-blue-background-color {
	background-color: var(
		--wp--preset--color--pale-cyan-blue
	) !important;
}

.has-vivid-cyan-blue-background-color {
	background-color: var(
		--wp--preset--color--vivid-cyan-blue
	) !important;
}

.has-vivid-purple-background-color {
	background-color: var(
		--wp--preset--color--vivid-purple
	) !important;
}

.has-primary-background-color {
	background-color: var(
		--wp--preset--color--primary
	) !important;
}

.has-secondary-background-color {
	background-color: var(
		--wp--preset--color--secondary
	) !important;
}

.has-foreground-background-color {
	background-color: var(
		--wp--preset--color--foreground
	) !important;
}

.has-background-background-color {
	background-color: var(
		--wp--preset--color--background
	) !important;
}

.has-tertiary-background-color {
	background-color: var(
		--wp--preset--color--tertiary
	) !important;
}

.has-black-border-color {
	border-color: var(--wp--preset--color--black) !important;
}

.has-cyan-bluish-gray-border-color {
	border-color: var(
		--wp--preset--color--cyan-bluish-gray
	) !important;
}

.has-white-border-color {
	border-color: var(--wp--preset--color--white) !important;
}

.has-pale-pink-border-color {
	border-color: var(
		--wp--preset--color--pale-pink
	) !important;
}

.has-vivid-red-border-color {
	border-color: var(
		--wp--preset--color--vivid-red
	) !important;
}

.has-luminous-vivid-orange-border-color {
	border-color: var(
		--wp--preset--color--luminous-vivid-orange
	) !important;
}

.has-luminous-vivid-amber-border-color {
	border-color: var(
		--wp--preset--color--luminous-vivid-amber
	) !important;
}

.has-light-green-cyan-border-color {
	border-color: var(
		--wp--preset--color--light-green-cyan
	) !important;
}

.has-vivid-green-cyan-border-color {
	border-color: var(
		--wp--preset--color--vivid-green-cyan
	) !important;
}

.has-pale-cyan-blue-border-color {
	border-color: var(
		--wp--preset--color--pale-cyan-blue
	) !important;
}

.has-vivid-cyan-blue-border-color {
	border-color: var(
		--wp--preset--color--vivid-cyan-blue
	) !important;
}

.has-vivid-purple-border-color {
	border-color: var(
		--wp--preset--color--vivid-purple
	) !important;
}

.has-primary-border-color {
	border-color: var(
		--wp--preset--color--primary
	) !important;
}

.has-secondary-border-color {
	border-color: var(
		--wp--preset--color--secondary
	) !important;
}

.has-foreground-border-color {
	border-color: var(
		--wp--preset--color--foreground
	) !important;
}

.has-background-border-color {
	border-color: var(
		--wp--preset--color--background
	) !important;
}

.has-tertiary-border-color {
	border-color: var(
		--wp--preset--color--tertiary
	) !important;
}

.has-vivid-cyan-blue-to-vivid-purple-gradient-background {
	background: var(
		--wp--preset--gradient--vivid-cyan-blue-to-vivid-purple
	) !important;
}

.has-light-green-cyan-to-vivid-green-cyan-gradient-background {
	background: var(
		--wp--preset--gradient--light-green-cyan-to-vivid-green-cyan
	) !important;
}

.has-luminous-vivid-amber-to-luminous-vivid-orange-gradient-background {
	background: var(
		--wp--preset--gradient--luminous-vivid-amber-to-luminous-vivid-orange
	) !important;
}

.has-luminous-vivid-orange-to-vivid-red-gradient-background {
	background: var(
		--wp--preset--gradient--luminous-vivid-orange-to-vivid-red
	) !important;
}

.has-very-light-gray-to-cyan-bluish-gray-gradient-background {
	background: var(
		--wp--preset--gradient--very-light-gray-to-cyan-bluish-gray
	) !important;
}

.has-cool-to-warm-spectrum-gradient-background {
	background: var(
		--wp--preset--gradient--cool-to-warm-spectrum
	) !important;
}

.has-blush-light-purple-gradient-background {
	background: var(
		--wp--preset--gradient--blush-light-purple
	) !important;
}

.has-blush-bordeaux-gradient-background {
	background: var(
		--wp--preset--gradient--blush-bordeaux
	) !important;
}

.has-luminous-dusk-gradient-background {
	background: var(
		--wp--preset--gradient--luminous-dusk
	) !important;
}

.has-pale-ocean-gradient-background {
	background: var(
		--wp--preset--gradient--pale-ocean
	) !important;
}

.has-electric-grass-gradient-background {
	background: var(
		--wp--preset--gradient--electric-grass
	) !important;
}

.has-midnight-gradient-background {
	background: var(
		--wp--preset--gradient--midnight
	) !important;
}

.has-small-font-size {
	font-size: var(--wp--preset--font-size--small) !important;
}

.has-medium-font-size {
	font-size: var(
		--wp--preset--font-size--medium
	) !important;
}

.has-large-font-size {
	font-size: var(--wp--preset--font-size--large) !important;
}

.has-x-large-font-size {
	font-size: var(
		--wp--preset--font-size--x-large
	) !important;
}

.has-x-small-font-size {
	font-size: var(
		--wp--preset--font-size--x-small
	) !important;
}

.has-xx-large-font-size {
	font-size: var(
		--wp--preset--font-size--xx-large
	) !important;
}

.has-xxx-large-font-size {
	font-size: var(
		--wp--preset--font-size--xxx-large
	) !important;
}

.has-xxxx-large-font-size {
	font-size: var(
		--wp--preset--font-size--xxxx-large
	) !important;
}

.has-xxxxx-large-font-size {
	font-size: var(
		--wp--preset--font-size--xxxxx-large
	) !important;
}

.has-albert-sans-font-family {
	font-family: var(
		--wp--preset--font-family--albert-sans
	) !important;
}

.has-alegreya-font-family {
	font-family: var(
		--wp--preset--font-family--alegreya
	) !important;
}

.has-arvo-font-family {
	font-family: var(
		--wp--preset--font-family--arvo
	) !important;
}

.has-bodoni-moda-font-family {
	font-family: var(
		--wp--preset--font-family--bodoni-moda
	) !important;
}

.has-cabin-font-family {
	font-family: var(
		--wp--preset--font-family--cabin
	) !important;
}

.has-chivo-font-family {
	font-family: var(
		--wp--preset--font-family--chivo
	) !important;
}

.has-commissioner-font-family {
	font-family: var(
		--wp--preset--font-family--commissioner
	) !important;
}

.has-cormorant-font-family {
	font-family: var(
		--wp--preset--font-family--cormorant
	) !important;
}

.has-courier-prime-font-family {
	font-family: var(
		--wp--preset--font-family--courier-prime
	) !important;
}

.has-crimson-pro-font-family {
	font-family: var(
		--wp--preset--font-family--crimson-pro
	) !important;
}

.has-dm-mono-font-family {
	font-family: var(
		--wp--preset--font-family--dm-mono
	) !important;
}

.has-dm-sans-font-family {
	font-family: var(
		--wp--preset--font-family--dm-sans
	) !important;
}

.has-domine-font-family {
	font-family: var(
		--wp--preset--font-family--domine
	) !important;
}

.has-eb-garamond-font-family {
	font-family: var(
		--wp--preset--font-family--eb-garamond
	) !important;
}

.has-epilogue-font-family {
	font-family: var(
		--wp--preset--font-family--epilogue
	) !important;
}

.has-figtree-font-family {
	font-family: var(
		--wp--preset--font-family--figtree
	) !important;
}

.has-fira-sans-font-family {
	font-family: var(
		--wp--preset--font-family--fira-sans
	) !important;
}

.has-fraunces-font-family {
	font-family: var(
		--wp--preset--font-family--fraunces
	) !important;
}

.has-ibm-plex-mono-font-family {
	font-family: var(
		--wp--preset--font-family--ibm-plex-mono
	) !important;
}

.has-ibm-plex-sans-font-family {
	font-family: var(
		--wp--preset--font-family--ibm-plex-sans
	) !important;
}

.has-josefin-sans-font-family {
	font-family: var(
		--wp--preset--font-family--josefin-sans
	) !important;
}

.has-jost-font-family {
	font-family: var(
		--wp--preset--font-family--jost
	) !important;
}

.has-libre-baskerville-font-family {
	font-family: var(
		--wp--preset--font-family--libre-baskerville
	) !important;
}

.has-libre-franklin-font-family {
	font-family: var(
		--wp--preset--font-family--libre-franklin
	) !important;
}

.has-literata-font-family {
	font-family: var(
		--wp--preset--font-family--literata
	) !important;
}

.has-lora-font-family {
	font-family: var(
		--wp--preset--font-family--lora
	) !important;
}

.has-merriweather-font-family {
	font-family: var(
		--wp--preset--font-family--merriweather
	) !important;
}

.has-montserrat-font-family {
	font-family: var(
		--wp--preset--font-family--montserrat
	) !important;
}

.has-newsreader-font-family {
	font-family: var(
		--wp--preset--font-family--newsreader
	) !important;
}

.has-nunito-font-family {
	font-family: var(
		--wp--preset--font-family--nunito
	) !important;
}

.has-open-sans-font-family {
	font-family: var(
		--wp--preset--font-family--open-sans
	) !important;
}

.has-overpass-font-family {
	font-family: var(
		--wp--preset--font-family--overpass
	) !important;
}

.has-petrona-font-family {
	font-family: var(
		--wp--preset--font-family--petrona
	) !important;
}

.has-piazzolla-font-family {
	font-family: var(
		--wp--preset--font-family--piazzolla
	) !important;
}

.has-playfair-display-font-family {
	font-family: var(
		--wp--preset--font-family--playfair-display
	) !important;
}

.has-plus-jakarta-sans-font-family {
	font-family: var(
		--wp--preset--font-family--plus-jakarta-sans
	) !important;
}

.has-poppins-font-family {
	font-family: var(
		--wp--preset--font-family--poppins
	) !important;
}

.has-raleway-font-family {
	font-family: var(
		--wp--preset--font-family--raleway
	) !important;
}

.has-roboto-font-family {
	font-family: var(
		--wp--preset--font-family--roboto
	) !important;
}

.has-roboto-slab-font-family {
	font-family: var(
		--wp--preset--font-family--roboto-slab
	) !important;
}

.has-rubik-font-family {
	font-family: var(
		--wp--preset--font-family--rubik
	) !important;
}

.has-sora-font-family {
	font-family: var(
		--wp--preset--font-family--sora
	) !important;
}

.has-source-sans-3-font-family {
	font-family: var(
		--wp--preset--font-family--source-sans-3
	) !important;
}

.has-source-serif-4-font-family {
	font-family: var(
		--wp--preset--font-family--source-serif-4
	) !important;
}

.has-space-mono-font-family {
	font-family: var(
		--wp--preset--font-family--space-mono
	) !important;
}

.has-texturina-font-family {
	font-family: var(
		--wp--preset--font-family--texturina
	) !important;
}

.has-work-sans-font-family {
	font-family: var(
		--wp--preset--font-family--work-sans
	) !important;
}

.has-inter-font-family {
	font-family: var(
		--wp--preset--font-family--inter
	) !important;
}

:where(.wp-site-blocks) > * {
	margin-block-start: 0;
	margin-block-end: 0;
}

.wp-container-core-social-links-layout-1.wp-container-core-social-links-layout-1 {
	justify-content: center;
}

.wp-container-core-social-links-layout-2.wp-container-core-social-links-layout-2 {
	justify-content: center;
}

.wp-container-core-social-links-layout-3.wp-container-core-social-links-layout-3 {
	justify-content: center;
}

.wp-container-core-social-links-layout-4.wp-container-core-social-links-layout-4 {
	justify-content: center;
}

.wp-container-core-social-links-layout-5.wp-container-core-social-links-layout-5 {
	justify-content: center;
}

.wp-container-core-social-links-layout-6.wp-container-core-social-links-layout-6 {
	justify-content: center;
}

.wp-container-core-navigation-layout-1.wp-container-core-navigation-layout-1 {
	justify-content: flex-start;
}

.wp-container-core-group-layout-1.wp-container-core-group-layout-1 {
	justify-content: space-between;
}

.wp-container-core-columns-layout-1.wp-container-core-columns-layout-1 {
	flex-wrap: nowrap;
}

.wp-elements-d63b33d30a3ee632cf4ad4e987f8f383 a {
	color: var(--wp--preset--color--background);
}

.wp-container-core-columns-layout-2.wp-container-core-columns-layout-2 {
	flex-wrap: nowrap;
}

.wp-elements-2a35f7445bcb2e60f253fa1e254bdeb6 a {
	color: var(--wp--preset--color--background);
}

.wp-container-core-group-layout-2.wp-container-core-group-layout-2 {
	flex-direction: column;
	align-items: flex-start;
}

.wp-container-core-columns-layout-3.wp-container-core-columns-layout-3 {
	flex-wrap: nowrap;
	gap: var(--wp--preset--spacing--70)
		var(--wp--preset--spacing--70);
}

.wp-container-core-columns-layout-4.wp-container-core-columns-layout-4 {
	flex-wrap: nowrap;
}

.wp-container-core-column-layout-6.wp-container-core-column-layout-6
	> * {
	margin-block-start: 0;
	margin-block-end: 0;
}

.wp-container-core-column-layout-6.wp-container-core-column-layout-6.wp-container-core-column-layout-6.wp-container-core-column-layout-6
	> *
	+ * {
	margin-block-start: var(--wp--preset--spacing--30);
	margin-block-end: 0;
}

.wp-container-core-columns-layout-5.wp-container-core-columns-layout-5 {
	flex-wrap: nowrap;
	gap: 2em var(--wp--preset--spacing--80);
}

.wp-container-core-column-layout-8.wp-container-core-column-layout-8
	> * {
	margin-block-start: 0;
	margin-block-end: 0;
}

.wp-container-core-column-layout-8.wp-container-core-column-layout-8.wp-container-core-column-layout-8.wp-container-core-column-layout-8
	> *
	+ * {
	margin-block-start: var(--wp--preset--spacing--30);
	margin-block-end: 0;
}

.wp-container-core-columns-layout-6.wp-container-core-columns-layout-6 {
	flex-wrap: nowrap;
	gap: 2em var(--wp--preset--spacing--80);
}

.wp-container-core-columns-layout-7.wp-container-core-columns-layout-7 {
	flex-wrap: nowrap;
	color: var(--wp--preset--color--background);
}

.wp-container-core-group-layout-7.wp-container-core-group-layout-7
	> * {
	margin-block-start: 0;
	margin-block-end: 0;
}

.wp-container-core-group-layout-7.wp-container-core-group-layout-7.wp-container-core-group-layout-7.wp-container-core-group-layout-7
	> *
	+ * {
	margin-block-start: 20px;
	margin-block-end: 0;
}

.wp-container-core-group-layout-8.wp-container-core-group-layout-8
	> * {
	margin-block-start: 0;
	margin-block-end: 0;
}

.wp-container-core-group-layout-8.wp-container-core-group-layout-8.wp-container-core-group-layout-8.wp-container-core-group-layout-8
	> *
	+ * {
	margin-block-start: 20px;
	margin-block-end: 0;
}

.wp-container-core-columns-layout-8.wp-container-core-columns-layout-8 {
	flex-wrap: nowrap;
	gap: 2em var(--wp--preset--spacing--60);
}

.wp-container-core-group-layout-9.wp-container-core-group-layout-9
	> * {
	margin-block-start: 0;
	margin-block-end: 0;
}

.wp-container-core-group-layout-9.wp-container-core-group-layout-9.wp-container-core-group-layout-9.wp-container-core-group-layout-9
	> *
	+ * {
	margin-block-start: 20px;
	margin-block-end: 0;
}

.wp-container-core-group-layout-10.wp-container-core-group-layout-10
	> * {
	margin-block-start: 0;
	margin-block-end: 0;
}

.wp-container-core-group-layout-10.wp-container-core-group-layout-10.wp-container-core-group-layout-10.wp-container-core-group-layout-10
	> *
	+ * {
	margin-block-start: 20px;
	margin-block-end: 0;
}

.wp-container-core-columns-layout-9.wp-container-core-columns-layout-9 {
	flex-wrap: nowrap;
	gap: 2em var(--wp--preset--spacing--60);
}

.wp-container-core-columns-layout-10.wp-container-core-columns-layout-10 {
	flex-wrap: nowrap;
}

.wp-elements-2d788679b569f0cd0b72f8803e025267 a {
	color: var(--wp--preset--color--background);
}

.wp-elements-0e3de18f5304b7e1fc0825fce2a2af5b a {
	color: var(--wp--preset--color--foreground);
}

.wp-container-core-group-layout-12.wp-container-core-group-layout-12 {
	flex-direction: column;
	align-items: center;
	justify-content: center;
}

.wp-container-core-group-layout-13.wp-container-core-group-layout-13 {
	gap: var(--wp--preset--spacing--40);
	flex-direction: column;
	align-items: flex-start;
}

.wp-container-core-columns-layout-11.wp-container-core-columns-layout-11 {
	flex-wrap: nowrap;
}

.wp-container-core-group-layout-14.wp-container-core-group-layout-14 {
	flex-direction: column;
	align-items: flex-start;
}

.wp-container-core-group-layout-16.wp-container-core-group-layout-16
	> * {
	margin-block-start: 0;
	margin-block-end: 0;
}

.wp-container-core-group-layout-16.wp-container-core-group-layout-16.wp-container-core-group-layout-16.wp-container-core-group-layout-16
	> *
	+ * {
	margin-block-start: var(--wp--preset--spacing--40);
	margin-block-end: 0;
}

.wp-container-core-group-layout-17.wp-container-core-group-layout-17
	> * {
	margin-block-start: 0;
	margin-block-end: 0;
}

.wp-container-core-group-layout-17.wp-container-core-group-layout-17.wp-container-core-group-layout-17.wp-container-core-group-layout-17
	> *
	+ * {
	margin-block-start: var(--wp--preset--spacing--40);
	margin-block-end: 0;
}

.wp-container-core-group-layout-18.wp-container-core-group-layout-18
	> * {
	margin-block-start: 0;
	margin-block-end: 0;
}

.wp-container-core-group-layout-18.wp-container-core-group-layout-18.wp-container-core-group-layout-18.wp-container-core-group-layout-18
	> *
	+ * {
	margin-block-start: var(--wp--preset--spacing--40);
	margin-block-end: 0;
}

.wp-container-core-group-layout-19.wp-container-core-group-layout-19
	> * {
	margin-block-start: 0;
	margin-block-end: 0;
}

.wp-container-core-group-layout-19.wp-container-core-group-layout-19.wp-container-core-group-layout-19.wp-container-core-group-layout-19
	> *
	+ * {
	margin-block-start: 0px;
	margin-block-end: 0;
}

.wp-container-core-group-layout-21.wp-container-core-group-layout-21
	> * {
	margin-block-start: 0;
	margin-block-end: 0;
}

.wp-container-core-group-layout-21.wp-container-core-group-layout-21.wp-container-core-group-layout-21.wp-container-core-group-layout-21
	> *
	+ * {
	margin-block-start: var(--wp--preset--spacing--40);
	margin-block-end: 0;
}

.wp-container-core-group-layout-22.wp-container-core-group-layout-22
	> * {
	margin-block-start: 0;
	margin-block-end: 0;
}

.wp-container-core-group-layout-22.wp-container-core-group-layout-22.wp-container-core-group-layout-22.wp-container-core-group-layout-22
	> *
	+ * {
	margin-block-start: var(--wp--preset--spacing--40);
	margin-block-end: 0;
}

.wp-container-core-group-layout-23.wp-container-core-group-layout-23
	> * {
	margin-block-start: 0;
	margin-block-end: 0;
}

.wp-container-core-group-layout-23.wp-container-core-group-layout-23.wp-container-core-group-layout-23.wp-container-core-group-layout-23
	> *
	+ * {
	margin-block-start: var(--wp--preset--spacing--40);
	margin-block-end: 0;
}

.wp-container-core-group-layout-24.wp-container-core-group-layout-24
	> * {
	margin-block-start: 0;
	margin-block-end: 0;
}

.wp-container-core-group-layout-24.wp-container-core-group-layout-24.wp-container-core-group-layout-24.wp-container-core-group-layout-24
	> *
	+ * {
	margin-block-start: 0px;
	margin-block-end: 0;
}

.wp-container-core-columns-layout-12.wp-container-core-columns-layout-12 {
	flex-wrap: nowrap;
	gap: 45px 45px;
}

.wp-container-core-group-layout-25.wp-container-core-group-layout-25 {
	flex-direction: column;
	align-items: flex-start;
}

.wp-container-core-group-layout-26.wp-container-core-group-layout-26
	> * {
	margin-block-start: 0;
	margin-block-end: 0;
}

.wp-container-core-group-layout-26.wp-container-core-group-layout-26.wp-container-core-group-layout-26.wp-container-core-group-layout-26
	> *
	+ * {
	margin-block-start: 0px;
	margin-block-end: 0;
}

.wp-container-core-group-layout-27.wp-container-core-group-layout-27
	> * {
	margin-block-start: 0;
	margin-block-end: 0;
}

.wp-container-core-group-layout-27.wp-container-core-group-layout-27.wp-container-core-group-layout-27.wp-container-core-group-layout-27
	> *
	+ * {
	margin-block-start: var(--wp--preset--spacing--60);
	margin-block-end: 0;
}

.wp-container-core-columns-layout-13.wp-container-core-columns-layout-13 {
	flex-wrap: nowrap;
	color: var(--wp--preset--color--background);
}

.wp-container-core-group-layout-28.wp-container-core-group-layout-28 {
	flex-direction: column;
	align-items: center;
	justify-content: center;
}

.wp-container-core-columns-layout-14.wp-container-core-columns-layout-14 {
	flex-wrap: nowrap;
}

.wp-container-core-columns-layout-15.wp-container-core-columns-layout-15 {
	flex-wrap: nowrap;
	gap: var(--wp--preset--spacing--80) 2em;
}

.wp-container-core-columns-layout-16.wp-container-core-columns-layout-16 {
	flex-wrap: nowrap;
	gap: var(--wp--preset--spacing--80) 2em;
}

.wp-container-core-group-layout-32.wp-container-core-group-layout-32
	> * {
	margin-block-start: 0;
	margin-block-end: 0;
}

.wp-container-core-group-layout-32.wp-container-core-group-layout-32.wp-container-core-group-layout-32.wp-container-core-group-layout-32
	> *
	+ * {
	margin-block-start: var(--wp--preset--spacing--80);
	margin-block-end: 0;
}

.wp-container-core-columns-layout-17.wp-container-core-columns-layout-17 {
	flex-wrap: nowrap;
	color: var(--wp--preset--color--background);
}

.wp-container-core-group-layout-33.wp-container-core-group-layout-33
	> :where(
		:not(.alignleft):not(.alignright):not(.alignfull)
	) {
	max-width: 860px;
	margin-left: auto !important;
	margin-right: auto !important;
}

.wp-container-core-group-layout-33.wp-container-core-group-layout-33
	> .alignwide {
	max-width: 860px;
}

.wp-container-core-group-layout-33.wp-container-core-group-layout-33
	.alignfull {
	max-width: none;
}

.wp-container-core-group-layout-34.wp-container-core-group-layout-34 {
	flex-direction: column;
	align-items: flex-start;
}

.wp-container-core-column-layout-26.wp-container-core-column-layout-26
	> * {
	margin-block-start: 0;
	margin-block-end: 0;
}

.wp-container-core-column-layout-26.wp-container-core-column-layout-26.wp-container-core-column-layout-26.wp-container-core-column-layout-26
	> *
	+ * {
	margin-block-start: 4px;
	margin-block-end: 0;
}

.wp-container-core-columns-layout-18.wp-container-core-columns-layout-18 {
	flex-wrap: nowrap;
}

.wp-container-core-column-layout-27.wp-container-core-column-layout-27
	> * {
	margin-block-start: 0;
	margin-block-end: 0;
}

.wp-container-core-column-layout-27.wp-container-core-column-layout-27.wp-container-core-column-layout-27.wp-container-core-column-layout-27
	> *
	+ * {
	margin-block-start: 4px;
	margin-block-end: 0;
}

.wp-container-core-columns-layout-19.wp-container-core-columns-layout-19 {
	flex-wrap: nowrap;
}

.wp-container-core-column-layout-28.wp-container-core-column-layout-28
	> * {
	margin-block-start: 0;
	margin-block-end: 0;
}

.wp-container-core-column-layout-28.wp-container-core-column-layout-28.wp-container-core-column-layout-28.wp-container-core-column-layout-28
	> *
	+ * {
	margin-block-start: 4px;
	margin-block-end: 0;
}

.wp-container-core-columns-layout-20.wp-container-core-columns-layout-20 {
	flex-wrap: nowrap;
}

.wp-container-core-column-layout-29.wp-container-core-column-layout-29
	> * {
	margin-block-start: 0;
	margin-block-end: 0;
}

.wp-container-core-column-layout-29.wp-container-core-column-layout-29.wp-container-core-column-layout-29.wp-container-core-column-layout-29
	> *
	+ * {
	margin-block-start: 4px;
	margin-block-end: 0;
}

.wp-container-core-columns-layout-21.wp-container-core-columns-layout-21 {
	flex-wrap: nowrap;
}

.wp-container-core-columns-layout-22.wp-container-core-columns-layout-22 {
	flex-wrap: nowrap;
	gap: 60px;
	color: var(--wp--preset--color--background);
}

.wp-container-core-group-layout-35.wp-container-core-group-layout-35
	> :where(
		:not(.alignleft):not(.alignright):not(.alignfull)
	) {
	max-width: 860px;
	margin-left: auto !important;
	margin-right: auto !important;
}

.wp-container-core-group-layout-35.wp-container-core-group-layout-35
	> .alignwide {
	max-width: 860px;
}

.wp-container-core-group-layout-35.wp-container-core-group-layout-35
	.alignfull {
	max-width: none;
}

.wp-container-core-group-layout-36.wp-container-core-group-layout-36 {
	flex-direction: column;
	align-items: flex-start;
}

.wp-container-core-column-layout-32.wp-container-core-column-layout-32
	> * {
	margin-block-start: 0;
	margin-block-end: 0;
}

.wp-container-core-column-layout-32.wp-container-core-column-layout-32.wp-container-core-column-layout-32.wp-container-core-column-layout-32
	> *
	+ * {
	margin-block-start: 4px;
	margin-block-end: 0;
}

.wp-container-core-columns-layout-23.wp-container-core-columns-layout-23 {
	flex-wrap: nowrap;
}

.wp-container-core-column-layout-33.wp-container-core-column-layout-33
	> * {
	margin-block-start: 0;
	margin-block-end: 0;
}

.wp-container-core-column-layout-33.wp-container-core-column-layout-33.wp-container-core-column-layout-33.wp-container-core-column-layout-33
	> *
	+ * {
	margin-block-start: 4px;
	margin-block-end: 0;
}

.wp-container-core-columns-layout-24.wp-container-core-columns-layout-24 {
	flex-wrap: nowrap;
}

.wp-container-core-column-layout-34.wp-container-core-column-layout-34
	> * {
	margin-block-start: 0;
	margin-block-end: 0;
}

.wp-container-core-column-layout-34.wp-container-core-column-layout-34.wp-container-core-column-layout-34.wp-container-core-column-layout-34
	> *
	+ * {
	margin-block-start: 4px;
	margin-block-end: 0;
}

.wp-container-core-columns-layout-25.wp-container-core-columns-layout-25 {
	flex-wrap: nowrap;
}

.wp-container-core-column-layout-35.wp-container-core-column-layout-35
	> * {
	margin-block-start: 0;
	margin-block-end: 0;
}

.wp-container-core-column-layout-35.wp-container-core-column-layout-35.wp-container-core-column-layout-35.wp-container-core-column-layout-35
	> *
	+ * {
	margin-block-start: 4px;
	margin-block-end: 0;
}

.wp-container-core-columns-layout-26.wp-container-core-columns-layout-26 {
	flex-wrap: nowrap;
}

.wp-container-core-columns-layout-27.wp-container-core-columns-layout-27 {
	flex-wrap: nowrap;
	gap: 60px;
}

.wp-container-core-columns-layout-28.wp-container-core-columns-layout-28 {
	flex-wrap: nowrap;
}

.wp-container-core-group-layout-38.wp-container-core-group-layout-38
	> * {
	margin-block-start: 0;
	margin-block-end: 0;
}

.wp-container-core-group-layout-38.wp-container-core-group-layout-38.wp-container-core-group-layout-38.wp-container-core-group-layout-38
	> *
	+ * {
	margin-block-start: var(--wp--preset--spacing--60);
	margin-block-end: 0;
}

.wp-container-core-columns-layout-29.wp-container-core-columns-layout-29 {
	flex-wrap: nowrap;
	color: var(--wp--preset--color--foreground);
}

.wp-container-core-group-layout-39.wp-container-core-group-layout-39
	> * {
	margin-block-start: 0;
	margin-block-end: 0;
}

.wp-container-core-group-layout-39.wp-container-core-group-layout-39.wp-container-core-group-layout-39.wp-container-core-group-layout-39
	> *
	+ * {
	margin-block-start: 1.5rem;
	margin-block-end: 0;
}

.wp-container-core-group-layout-40.wp-container-core-group-layout-40
	> * {
	margin-block-start: 0;
	margin-block-end: 0;
}

.wp-container-core-group-layout-40.wp-container-core-group-layout-40.wp-container-core-group-layout-40.wp-container-core-group-layout-40
	> *
	+ * {
	margin-block-start: 1.5rem;
	margin-block-end: 0;
}

.wp-container-core-group-layout-41.wp-container-core-group-layout-41
	> * {
	margin-block-start: 0;
	margin-block-end: 0;
}

.wp-container-core-group-layout-41.wp-container-core-group-layout-41.wp-container-core-group-layout-41.wp-container-core-group-layout-41
	> *
	+ * {
	margin-block-start: 1.5rem;
	margin-block-end: 0;
}

.wp-container-core-post-template-layout-1.wp-container-core-post-template-layout-1 {
	grid-template-columns: repeat(3, minmax(0, 1fr));
}

.wp-container-core-group-layout-42.wp-container-core-group-layout-42
	> * {
	margin-block-start: 0;
	margin-block-end: 0;
}

.wp-container-core-group-layout-42.wp-container-core-group-layout-42.wp-container-core-group-layout-42.wp-container-core-group-layout-42
	> *
	+ * {
	margin-block-start: var(--wp--preset--spacing--60);
	margin-block-end: 0;
}

.wp-container-core-columns-layout-30.wp-container-core-columns-layout-30 {
	flex-wrap: nowrap;
	/* color: var(--wp--preset--color--background); */
	color: var(--wp--preset--color--vivid-red);
}

.wp-container-core-column-layout-40.wp-container-core-column-layout-40
	> * {
	margin-block-start: 0;
	margin-block-end: 0;
}

.wp-container-core-column-layout-40.wp-container-core-column-layout-40.wp-container-core-column-layout-40.wp-container-core-column-layout-40
	> *
	+ * {
	margin-block-start: var(--wp--preset--spacing--30);
	margin-block-end: 0;
	/* color: var(--wp--preset--color--background); */
	/* color: var(--wp--preset--color--vivid-red); */
}

.wp-container-core-column-layout-41.wp-container-core-column-layout-41
	> * {
	margin-block-start: 0;
	margin-block-end: 0;
}

.wp-container-core-column-layout-41.wp-container-core-column-layout-41.wp-container-core-column-layout-41.wp-container-core-column-layout-41
	> *
	+ * {
	margin-block-start: var(--wp--preset--spacing--30);
	margin-block-end: 0;
}

.wp-container-core-columns-layout-31.wp-container-core-columns-layout-31 {
	flex-wrap: nowrap;
	gap: 2em var(--wp--preset--spacing--70);
	/* color: var(--wp--preset--color--background); */
	/* color: var(--wp--preset--color--vivid-red); */
}

.wp-container-core-column-layout-42.wp-container-core-column-layout-42
	> * {
	margin-block-start: 0;
	margin-block-end: 0;
}

.wp-container-core-column-layout-42.wp-container-core-column-layout-42.wp-container-core-column-layout-42.wp-container-core-column-layout-42
	> *
	+ * {
	margin-block-start: var(--wp--preset--spacing--30);
	margin-block-end: 0;
}

.wp-container-core-columns-layout-32.wp-container-core-columns-layout-32 {
	flex-wrap: nowrap;
	gap: 2em var(--wp--preset--spacing--70);
}

.wp-container-core-group-layout-43.wp-container-core-group-layout-43
	> * {
	margin-block-start: 0;
	margin-block-end: 0;
}

.wp-container-core-group-layout-43.wp-container-core-group-layout-43.wp-container-core-group-layout-43.wp-container-core-group-layout-43
	> *
	+ * {
	margin-block-start: var(--wp--preset--spacing--60);
	margin-block-end: 0;
}

.wp-container-core-columns-layout-33.wp-container-core-columns-layout-33 {
	flex-wrap: nowrap;
	color: var(--wp--preset--color--vivid-red);
}

.wp-container-core-social-links-layout-7.wp-container-core-social-links-layout-7 {
	gap: 16px 16px;
	justify-content: flex-start;
}

.wp-container-core-column-layout-44.wp-container-core-column-layout-44
	> * {
	margin-block-start: 0;
	margin-block-end: 0;
}

.wp-container-core-column-layout-44.wp-container-core-column-layout-44.wp-container-core-column-layout-44.wp-container-core-column-layout-44
	> *
	+ * {
	margin-block-start: 16px;
	margin-block-end: 0;
	color: var(--wp--preset--color--vivid-red);
}

.wp-container-core-column-layout-46.wp-container-core-column-layout-46
	> * {
	margin-block-start: 0;
	margin-block-end: 0;
}

.wp-container-core-column-layout-46.wp-container-core-column-layout-46.wp-container-core-column-layout-46.wp-container-core-column-layout-46
	> *
	+ * {
	margin-block-start: 0px;
	margin-block-end: 0;
}

.wp-container-core-columns-layout-34.wp-container-core-columns-layout-34 {
	flex-wrap: nowrap;
	gap: 40px;
}

.wp-container-core-columns-layout-35.wp-container-core-columns-layout-35 {
	flex-wrap: nowrap;
}

.wp-container-core-group-layout-45.wp-container-core-group-layout-45
	> * {
	margin-block-start: 0;
	margin-block-end: 0;
}

.wp-container-core-group-layout-45.wp-container-core-group-layout-45.wp-container-core-group-layout-45.wp-container-core-group-layout-45
	> *
	+ * {
	margin-block-start: 0;
	margin-block-end: 0;
}

.wp-duotone-unset-2.wp-block-post-featured-image img,
.wp-duotone-unset-2.wp-block-post-featured-image
	.wp-block-post-featured-image__placeholder,
.wp-duotone-unset-2.wp-block-post-featured-image
	.components-placeholder__illustration,
.wp-duotone-unset-2.wp-block-post-featured-image
	.components-placeholder::before {
	filter: unset;
}

.wp-duotone-unset-3.wp-block-post-featured-image img,
.wp-duotone-unset-3.wp-block-post-featured-image
	.wp-block-post-featured-image__placeholder,
.wp-duotone-unset-3.wp-block-post-featured-image
	.components-placeholder__illustration,
.wp-duotone-unset-3.wp-block-post-featured-image
	.components-placeholder::before {
	filter: unset;
}

.wp-duotone-unset-4.wp-block-post-featured-image img,
.wp-duotone-unset-4.wp-block-post-featured-image
	.wp-block-post-featured-image__placeholder,
.wp-duotone-unset-4.wp-block-post-featured-image
	.components-placeholder__illustration,
.wp-duotone-unset-4.wp-block-post-featured-image
	.components-placeholder::before {
	filter: unset;
}

:root {
	--font-headings: unset;
	--font-base: unset;
	--font-headings-default: -apple-system, BlinkMacSystemFont,
		'Segoe UI', Roboto, Oxygen-Sans, Ubuntu, Cantarell,
		'Helvetica Neue', sans-serif;
	--font-base-default: -apple-system, BlinkMacSystemFont,
		'Segoe UI', Roboto, Oxygen-Sans, Ubuntu, Cantarell,
		'Helvetica Neue', sans-serif;
}

@font-face {
	font-family: Inter;
	font-style: normal;
	font-weight: 700;
	font-display: fallback;
	src: url('https://davidssoftwareengineer.wpcomstaging.com/wp-content/themes/creatio-2/assets/fonts/inter_normal_700.ttf')
		format('truetype');
}

@font-face {
	font-family: Inter;
	font-style: normal;
	font-weight: 500;
	font-display: fallback;
	src: url('https://davidssoftwareengineer.wpcomstaging.com/wp-content/themes/creatio-2/assets/fonts/inter_normal_500.ttf')
		format('truetype');
}

@font-face {
	font-family: Inter;
	font-style: normal;
	font-weight: 600;
	font-display: fallback;
	src: url('https://davidssoftwareengineer.wpcomstaging.com/wp-content/themes/creatio-2/assets/fonts/inter_normal_600.ttf')
		format('truetype');
}

@font-face {
	font-family: Inter;
	font-style: normal;
	font-weight: 400;
	font-display: fallback;
	src: url('https://davidssoftwareengineer.wpcomstaging.com/wp-content/themes/creatio-2/assets/fonts/inter_normal_400.ttf')
		format('truetype');
}

@font-face {
	font-family: Inter;
	font-style: normal;
	font-weight: 700;
	font-display: fallback;
	src: url('https://davidssoftwareengineer.wpcomstaging.com/wp-content/themes/creatio-2/assets/fonts/inter_normal_700.ttf')
		format('truetype');
}

@font-face {
	font-family: Inter;
	font-style: normal;
	font-weight: 500;
	font-display: fallback;
	src: url('https://davidssoftwareengineer.wpcomstaging.com/wp-content/themes/creatio-2/assets/fonts/inter_normal_500.ttf')
		format('truetype');
}

@font-face {
	font-family: Inter;
	font-style: normal;
	font-weight: 600;
	font-display: fallback;
	src: url('https://davidssoftwareengineer.wpcomstaging.com/wp-content/themes/creatio-2/assets/fonts/inter_normal_600.ttf')
		format('truetype');
}

@font-face {
	font-family: Inter;
	font-style: normal;
	font-weight: 400;
	font-display: fallback;
	src: url('https://davidssoftwareengineer.wpcomstaging.com/wp-content/themes/creatio-2/assets/fonts/inter_normal_400.ttf')
		format('truetype');
}

.sd-social-icon .sd-content ul li a.sd-button > span {
	margin-left: 0;
}

a {
	text-decoration: none;
	transition:
		color 0.3s ease,
		background-color 0.3s ease;
	border-radius: 5px;
}

a:hover {
	color: var(--wp--preset--color--icon) !important;
	background-color: var(--wp--preset--color--iconBG);
	font-weight: 900;
}

a:active {
	box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.2);
}

a img {
	transition: transform 0.3s ease-in-out;
}

a:hover img {
	transform: scale(1.05);
}

body {
	font-family: 'Roboto', 'Montserrat', 'Lato',
		'Playfair Display', 'Georgia', 'Open Sans', sans-serif !important;
}

h1,
h2,
h3,
h4,
h5,
h6 {
	font-family: 'Roboto', 'Montserrat', 'Lato',
		'Source Code Pro', 'Playfair Display', 'Georgia',
		'Open Sans', sans-serif !important;
	font-family: 'Playfair Display', 'Georgia', 'Open Sans',
		sans-serif !important;
}

p {
	font-family: 'Roboto', 'Montserrat', 'Lato',
		'Playfair Display', 'Georgia', 'Open Sans', sans-serif !important;
}

// color switcher
.header {
	&-section {
		background-color: var(
			--wp--preset--color--navBg
		) !important;

		background: var(--wp--preset--color--navBg) !important;

		&-header {
			color: var(--wp--preset--color--headerA) !important;
		}

		&-text {
			color: var(--wp--preset--color--nav--text) !important;
			border-radius: 10px;

			&:active,
			&:hover {
				color: var(--wp--preset--color--icon) !important;
				background-color: var(--wp--preset--color--iconBG);
			}

			a {
				text-decoration: none !important;
			}
		}

		&-url {
			color: var(--wp--preset--color--headerA) !important;

			&:active,
			&:hover {
				color: var(
					--wp--preset--color--links-hover
				) !important;
			}
		}

		&-accent {
			color: var(--wp--preset--color--headerA) !important;

			&:active,
			&:hover {
				color: var(
					--wp--preset--color--links-hover
				) !important;
			}
		}
	}
}

.hero {
	&-section {
		background-color: var(
			--wp--preset--color--backgroundA
		) !important;
		background: var(
			--wp--preset--color--backgroundA
		) !important;

		&-header {
			color: var(--wp--preset--color--headerA) !important;
		}
	}
}

.about {
	&-section {
		background-color: var(
			--wp--preset--color--backgroundB
		) !important;
		background: var(
			--wp--preset--color--backgroundB
		) !important;

		&-header {
			color: var(--wp--preset--color--headerB) !important;
		}

		&-text {
			color: var(--wp--preset--color--subtextB) !important;
		}

		&-accent {
			color: var(--wp--preset--color--accent) !important;
		}

		&-url {
			color: var(--wp--preset--color--links) !important;

			&:active,
			&:hover {
				color: var(
					--wp--preset--color--links-hover
				) !important;
			}
		}
	}
}

.info {
	&-section {
		background-color: var(
			--wp--preset--color--backgroundA
		) !important;

		background: var(
			--wp--preset--color--backgroundA
		) !important;

		&-header {
			color: var(--wp--preset--color--headerA) !important;
		}

		&-text {
			color: var(--wp--preset--color--subtextA) !important;
		}

		&-accent {
			color: var(--wp--preset--color--accent) !important;
		}

		&-url {
			color: var(--wp--preset--color--links) !important;

			&:active,
			&:hover {
				color: var(
					--wp--preset--color--links-hover
				) !important;
			}
		}
	}
}

.projects {
	&-section {
		background-color: var(
			--wp--preset--color--backgroundB
		) !important;

		background: var(
			--wp--preset--color--backgroundB
		) !important;

		&-header {
			color: var(--wp--preset--color--headerB) !important;
		}

		&-text {
			color: var(--wp--preset--color--subtextB) !important;
		}

		&-accent {
			color: var(--wp--preset--color--accent) !important;
		}

		&-url {
			color: var(--wp--preset--color--links) !important;

			&:active,
			&:hover {
				color: var(
					--wp--preset--color--links-hover
				) !important;
			}
		}
	}
}

.skills {
	&-section {
		background-color: var(
			--wp--preset--color--backgroundA
		) !important;

		background: var(
			--wp--preset--color--backgroundA
		) !important;

		&-header {
			color: var(--wp--preset--color--headerA) !important;
		}

		&-text {
			color: var(--wp--preset--color--subtextA) !important;
		}

		&-accent {
			color: var(--wp--preset--color--accent) !important;
		}

		&-url {
			color: var(--wp--preset--color--links) !important;

			&:active,
			&:hover {
				color: var(
					--wp--preset--color--links-hover
				) !important;
			}
		}
	}
}

.education {
	&-section {
		background-color: var(
			--wp--preset--color--backgroundB
		) !important;

		background: var(
			--wp--preset--color--backgroundB
		) !important;

		&-header {
			color: var(--wp--preset--color--headerB) !important;
		}

		&-text {
			color: var(--wp--preset--color--subtextB) !important;
		}

		&-accent {
			color: var(--wp--preset--color--accent) !important;
		}

		&-url {
			color: var(--wp--preset--color--links) !important;

			&:active,
			&:hover {
				color: var(
					--wp--preset--color--links-hover
				) !important;
			}
		}
	}
}

.experience {
	&-section {
		background-color: var(
			--wp--preset--color--backgroundA
		) !important;

		background: var(
			--wp--preset--color--backgroundA
		) !important;

		&-header {
			color: var(--wp--preset--color--headerA) !important;
		}

		&-text {
			color: var(--wp--preset--color--subtextA) !important;
		}

		&-accent {
			color: var(--wp--preset--color--accent) !important;
		}

		&-url {
			color: var(--wp--preset--color--links) !important;

			&:active,
			&:hover {
				color: var(
					--wp--preset--color--links-hover
				) !important;
			}
		}
	}
}

.blog {
	&-section {
		background-color: var(
			--wp--preset--color--backgroundB
		) !important;

		background: var(
			--wp--preset--color--backgroundB
		) !important;

		&-header {
			color: var(--wp--preset--color--headerB) !important;
			max-width: var(--wp--style--global--wide-size);
			margin: 0 auto;
		}

		&-text {
			color: var(--wp--preset--color--subtextB) !important;
		}

		&-accent {
			color: var(--wp--preset--color--accent) !important;
		}

		&-url {
			color: var(--wp--preset--color--links) !important;

			&:active,
			&:hover {
				color: var(
					--wp--preset--color--links-hover
				) !important;
			}
		}
	}
}

.endorsements {
	&-section {
		background-color: var(
			--wp--preset--color--backgroundA
		) !important;

		background: var(
			--wp--preset--color--backgroundA
		) !important;

		&-header {
			color: var(--wp--preset--color--headerA) !important;
		}

		&-text {
			color: var(--wp--preset--color--subtextA) !important;
		}

		&-accent {
			color: var(--wp--preset--color--accent) !important;
		}

		&-url {
			color: var(--wp--preset--color--links) !important;

			&:active,
			&:hover {
				color: var(
					--wp--preset--color--links-hover
				) !important;
			}
		}
	}
}

.contact {
	&-section {
		background-color: var(
			--wp--preset--color--backgroundB
		) !important;

		background: var(
			--wp--preset--color--backgroundB
		) !important;

		&-header {
			color: var(--wp--preset--color--headerB) !important;
		}

		&-text {
			color: var(--wp--preset--color--subtextB) !important;
		}

		&-accent {
			color: var(--wp--preset--color--accent) !important;
		}

		&-url {
			color: var(--wp--preset--color--links) !important;

			&:active,
			&:hover {
				color: var(
					--wp--preset--color--links-hover
				) !important;
			}
		}

		&-icons {
			color: var(--wp--preset--color--icon) !important;
			background-color: var(
				--wp--preset--color--iconBG
			) !important;
			border-radius: 5px;

			&:active,
			&:hover {
				transition: all 0.3s ease;
				border-radius: 10px;
			}
		}
	}
}

.footer {
	&-section {
		background-color: var(
			--wp--preset--color--backgroundA
		) !important;

		background: var(
			--wp--preset--color--backgroundA
		) !important;

		&-header {
			color: var(--wp--preset--color--headerA) !important;
		}

		&-text {
			color: var(--wp--preset--color--subtextA) !important;

			&:active,
			&:hover {
				color: var(
					--wp--preset--color--links-accent
				) !important;
			}
		}

		&-accent {
			color: var(--wp--preset--color--accent) !important;
		}

		&-url {
			color: var(--wp--preset--color--links) !important;

			&:active,
			&:hover {
				color: var(
					--wp--preset--color--links-hover
				) !important;
			}
		}

		&-icons {
			color: var(--wp--preset--color--icon) !important;
			background-color: var(
				--wp--preset--color--iconBG
			) !important;
		}
	}
}

.wp-block-social-link-anchor {
	border-radius: 50%;
	&:hover,
	&:active {
		border-radius: 10px;
	}
}

.no-underline {
	text-decoration: none !important;
}

i {
	&:hover {
		transition: transform 0.75s ease-in-out;
		transform: rotate(360deg);
	}
}
