@import "../../../breakpoints/breakpoints.scss";
@import "../../../mixins/mixins.scss";

.footer {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 10px 0;
  text-decoration: none;
}
