/**
*
* Testing
*
**/
.footer {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 10px 0;
  text-decoration: none;
}/*# sourceMappingURL=Footer.css.map */